import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios"
import { get } from "lodash-es"
import { getToken,removeToken} from "@/lib/cookies";
import Base64 from './js-base64'
import {siteConfig} from '@/config/site'
import encrypt from './encrypt'
const baseUtil = new Base64()
const logOut = ()=>{
  if(getToken()){
    removeToken()
    setTimeout(()=>{
      window.location.reload()
    })
  }
}
/** 创建请求实例 */
function createService() {
    // 创建一个 Axios 实例
    const service = axios.create()
    // 请求拦截
    service.interceptors.request.use(
        (config) => config,
        // 发送失败
        (error) => Promise.reject(error)
    )
    // 响应拦截（可根据具体业务作出相应的调整）
    service.interceptors.response.use(
        (response) => {
            // apiData 是 API 返回的数据
            const apiData= response.data as any
            // 这个 Code 是和后端约定的业务 Code
            const code = apiData.resp_code
            const msg = apiData.resp_msg
            // 如果没有 Code, 代表这不是项目后端开发的 API
           if(msg === 'Not Authenticated'){
            logOut()
           }
            if (code === undefined) {
                return Promise.reject(new Error("非本系统的接口"))
            } else {
                switch (code) {
                    case 0:
                        // code === 0 代表没有错误
                        return apiData
                    case 401:
                      logOut()
                      break
                      case 402:
                        logOut()
                        break
                    case 1001:
                      logOut()
                      break
                    case 1002:
                      logOut()
                        //登陆过期
                        return
                    default:
                        // 不是正确的 Code
                        return apiData
                }
            }
        },
        (error) => {
            // Status 是 HTTP 状态码
            const status = get(error, "response.status")
            switch (status) {
                case 400:
                    error.message = "请求错误"
                    break
                case 401:
                    // Token 过期时，直接退出登录并强制刷新页面（会重定向到登录页）
                    removeToken()
                    break
                case 403:
                    error.message = "拒绝访问"
                    break
                case 404:
                    error.message = "请求地址出错"
                    break
                case 408:
                    error.message = "请求超时"
                    break
                case 500:
                    error.message = "服务器内部错误"
                    break
                case 501:
                    error.message = "服务未实现"
                    break
                case 502:
                    error.message = "网关错误"
                    break
                case 503:
                    error.message = "服务不可用"
                    break
                case 504:
                    error.message = "网关超时"
                    break
                case 505:
                    error.message = "HTTP 版本不受支持"
                    break
                default:
                    break
            }
            return Promise.reject(error)
        }
    )
    return service
}

/** 创建请求方法 */
function createRequestFunction(service: AxiosInstance) {
    return function <T>(config: AxiosRequestConfig, params: any): Promise<T> {
        const token = getToken();
        const defaultConfig = `${siteConfig.clientId}:${siteConfig.clientSecret}`
        const configDefault = {
            headers: {
                // 携带 Token
                tenant:siteConfig.clientId,
                sign:encrypt(params.data ? params.data : params.params ? params.params : ""),
                Authorization: token ? "Bearer " + token : 'Basic '+ baseUtil.encode(defaultConfig),
                "Content-Type": get(config, "headers.Content-Type", "application/json"),
            },
            timeout: 50000,
            baseURL: process.env.NEXT_PUBLIC_SERVER,
           ...params
        }
        return service(Object.assign(configDefault, config))
    }
}

/** 用于网络请求的实例 */
export const service = createService()
/** 用于网络请求的方法 */
export const request = createRequestFunction(service)
