/** 统一处理 Cookie */
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
const getTokenKey = () => {
  let token = 'token'
  return token
}
const getUuidKey = () => {
  let uuid = 'uuid'
  return uuid
}

export const getToken = () => {
  let token = getCookie(getTokenKey(),{domain:process.env.NEXT_PUBLIC_DOMAIN});
  return token;
}
export const setToken = (token: string,) => {
  setCookie(getTokenKey(), token,{domain:process.env.NEXT_PUBLIC_DOMAIN});
}
export const removeToken = () => {
  deleteCookie(getTokenKey(), {domain:process.env.NEXT_PUBLIC_DOMAIN});
}
export const getUuid = () => {
  return getCookie(getUuidKey());
}
export const setUuid = (uuid: string) => {
  setCookie(getUuidKey(), uuid);
}
export const removeUuid = () => {
  deleteCookie(getUuidKey(), { domain: process.env.NEXT_PUBLIC_DOMAIN });
}
