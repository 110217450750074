import { request } from '@/lib/service'

/** 登录注册 */
export const loginApi = (res: any) => {
  return request(
    {
      url: '/api-uaa/oauth/token',
      method: 'post',
      params: res,
    },
    { params: res },
  )
}
//获取用户信息
export const getUserInfo = () => {
  return request(
    {
      url: '/api-user/users/current',
      method: 'get',
    },
    {},
  )
}

// 登录获取验证码
export function getPhoneCode(mobile?: any) {
  return request(
    {
      url: `/api-user/user/sendLoginSMS`,
      method: 'get',
    },
    { params: mobile },
  )
}

// 忘记密码发送验证码
export const forgetSendCode = (mobile?: any) => {
  return request(
    {
      url: `/api-user/register/forgotPassword`,
      method: 'get',
    },
    { params: mobile },
  )
}

// 修改密码
export const modifyPassword = (data?: object) => {
  return request(
    {
      url: `/api-user/register/resetPassword`,
      method: 'post',
    },
    { data },
  )
}
// 修改账号密码
export const modifyPersonPassword = (data?: object) => {
  return request(
    {
      url: `/api-user/account/modifyPassword`,
      method: 'post',
    },
    { data },
  )
}

//获取图片域名
export const configListBefore = (params: any) => {
  return request<void>(
    { method: 'get', url: '/api-user/openApi/configListBefore' },
    {
      params: { value: params },
    },
  )
}

// nav埋点统计
export const frontAddPageClick = (data: any) => {
  return request(
    {
      url: `eesa-industrial-map/pageClick/front/V1.0/frontAddPageClick`,
      method: 'post',
    },
    { data },
  )
}

// 记录登录用户
export const frontAddLoginUser = () => {
  return request(
    {
      url: `/eesa-industrial-map/loginUser/front/V1.0/frontAddLoginUser`,
      method: 'post',
    },
    {},
  )
}

// 退出
export const loginOutApi = (token: any) => {
  return request(
    {
      url: `/api-uaa/oauth/remove/token`,
      method: 'post',
    },
    { params: { access_token: token } },
  )
}
// 授权
export const addUserRole = () => {
  return request(
    {
      url: `/api-user/userRole/front/v1.0/addUserRole`,
      method: 'post',
    },
    {},
  )
}
